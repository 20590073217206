<template>
  <div align="center">
    <v-toolbar dense class="rounded">
      <v-btn icon class="hidden-xs-only" @click="$router.push('/gametype')">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-toolbar-title>TÉRMINOS Y CONDICIONES TAR POKER</v-toolbar-title>

      <v-spacer></v-spacer>
    </v-toolbar>

    <div class="mt-3 rounded white pa-3 text-justify">
      
      <p>
    <strong>TAR POKER Términos y Condiciones</strong>
</p>
<p>
    <strong>CARRERA DE PUNTOS HOLD´EM Y OMAHA</strong>
</p>
<p>
    Todos  los jugadores de los sitios de la Red Latina de Póker suman puntos
    con cada  participación en mesas vivas (públicas o privadas).
</p>
<p>
    Busca  tu lugar en la tabla de posiciones.
</p>
<p>
    Actualización  periódica. HOLD´EM y OMAHA por separado.
</p>
<p>
    <strong>Información para los usuarios:</strong>
</p>
<p>
    -  Cada cuenta puede ser usada por una sola persona. Si detectamos que una
    cuenta  es utilizada por más de una persona, automáticamente no se tendrán
    en cuenta  los puntos obtenidos en ese día y se descontará el 100% de lo
    acumulado hasta  ese momento.
</p>
<p>
    -  Ante cualquier caso de COLUSIÓN el jugador quedará descalificado
    definitivamente de TAR Poker (además de ser retenido el importe de su caja).
</p>
<p>
    - En  los casos en que se detecten movimientos irregulares o fraudulentos de
    saldo  entre dos usuarios (sean o no del mismo panel) la red se reserva el
    derecho de  no abonar las ganancias obtenidas de manera irregular como así
    también de  proceder a congelar el saldo de las cuentas de los usuarios
    involucrados en la  maniobra.
</p>
<p id="tor">
    <strong>TORNEOS:</strong>
</p>
<p>
    Todos  los jugadores de los sitios de la Red Latina de Póker suman puntos
    participando  en nuestros torneos
</p>
<p>
    Las carreras serán quincenales y las  posiciones se actualizarán
    periódicamente.
</p>
<p>
    <strong>COMO SUMAR PUNTOS:</strong>
</p>
<p>
    Solo  obtienen puntos los usuarios que hayan pagado el ticket (no entradas
    free/promocionales).
</p>
<p>
    Participando  en cualquiera de nuestros torneos: 1 punto
</p>
<p>
    Registrándose  antes de que termine el primer nivel de ciegas: 3 puntos
</p>
<p>
    Llegando  a la mesa final: 5 puntos
</p>
<p>
    Ganando  un torneo: 10 puntos
</p>
<p>
    <strong>IMPORTANTE:</strong>
</p>
<p>
    No  participan los torneos
</p>
<p>
    -  SPIN &amp; GO
</p>
<p>
    -  SIT &amp; GO
</p>
<p>
    -  TORNEOS FREE ROLL
</p>
<p>
    <strong>Información para los usuarios:</strong>
</p>
<p>
    -  Cada cuenta puede ser usada por una sola persona. Si detectamos que una
    cuenta  es utilizada por más de una persona, automáticamente no se tendrán
    en cuenta  los puntos obtenidos en ese día y se descontará el 100% de lo
    acumulado hasta  ese momento.
</p>
<p>
    -  Ante cualquier caso de COLUSIÓN el jugador quedará descalificado
    definitivamente de TAR Poker (además de ser retenido el importe de su caja).
</p>
<p>
    - En  los casos en que se detecten movimientos irregulares o fraudulentos de
    saldo  entre dos usuarios (sean o no del mismo panel) la red se reserva el
    derecho de  no abonar las ganancias obtenidas de manera irregular como así
    también de  proceder a congelar el saldo de las cuentas de los usuarios
    involucrados en la  maniobra.
</p>
<p id="reg">
    <strong><u>Reglamento</u></strong>
</p>
<p>
    Esta  regulación se aplica a todos los torneos accesibles por el Lobby y
    ofrecidos  por el Proveedor programados y Sit&amp;Go, así como a juego en
    cash, a los que  se puede acceder en la sección «Tipos de Póker». Los juegos
    disponibles en la  sección «Tipos de póker» también se pueden usar en la
    versión dinero divertido  y son absolutamente gratuitos.
</p>
<p>
    El  usuario reconoce y acepta todas las disposiciones de este Reglamento,
    así como  los Términos y condiciones de uso del sitio. En caso de
    modificación de este  Reglamento, el uso del sitio y los servicios
    relacionados por parte del jugador  implica la aceptación automática e
    incondicional de los cambios realizados. Si  el usuario considera que la
    modificación no era aceptable, se compromete a  abstenerse de usar el sitio
    y / o los servicios relacionados.
</p>
<p>
    Al  validar la opción «Aceptar» en el momento del registro, el usuario se
    compromete a aceptar, reconocer y cumplir con todas las reglas de este
    reglamento que constituyen una parte integral y adicional de los Términos y
    Condiciones.  En caso de conflicto entre las disposiciones de este
    reglamento y los términos  y condiciones, prevalecerá este último. El
    Licenciatario utiliza solo reglas,  cuotas, códigos y algoritmos
    predefinidos verificados por compañías autorizadas  y certificadas por la
    Agencia de Aduanas y Monopolios.
</p>
<p>
    <strong>Texas Hold´em</strong>
</p>
<p>
    Texas  Hold’em es una de las variaciones de póker más populares del mundo.
    El juego  tiene lugar con una baraja regular de 52 cartas francesas,
    divididas en 4 palos  (picas, trébol, corazones y diamantes), con 13 cartas
    en secuencia de 2 a as.  Los jugadores, desde un mínimo de 2 hasta un máximo
    de 10, deben componer la  mejor mano de 5 cartas eligiendo entre las dos
    cartas personales y las cinco en  la mesa. Las posibles combinaciones se
    obtienen utilizando alternativamente:
</p>
<p>
    2  cartas en la mano + 3 cartas comunes
</p>
<p>
    1  carta en mano + 4 cartas comunes
</p>
<p>
    solo  las 5 cartas comunes
</p>
<p>
    Texas  Hold’em puede tener diferentes estructuras de límite en apuestas como
    «Limit» y  «No Limit».
</p>
<p>
    <strong>Limit (L) o Fixed Limit (FL)</strong>
</p>
<p>
    En  las dos primeras rondas de apuestas (preflop y flop), la apuesta y el
    aumento  están vinculados al tamaño de la ciega grande con un máximo de 3
    aumentos en la  apuesta original; en el turn y el river se aplica la misma
    lógica, pero la  cantidad que se puede apostar corresponde al doble de la
    ciega grande.
</p>
<p>
    <strong>No Limit (NL)</strong>
</p>
<p>
    En  este modo de juego, no hay límites máximos de apuestas o limitaciones en
    el  número de apuestas. La capacidad del jugador de apostar todos sus
    activos  (All-In) en una única apuesta y en cualquier momento hace que esta
    variante sea  particularmente emocionante. Sin embargo, hay un límite mínimo
    de apuesta o  aumento igual al límite actual o la última apuesta o aumento.
</p>
<p>
    Las  posibles combinaciones de manos
</p>
<p>
    Escalera  real: la mejor mano de póker, en secuencia Diez, Jack, Reina, Rey
    y As del  mismo palo.
</p>
<p>
    Escalera  de color: cinco cartas del mismo palo en secuencia. Difiere de la
    escalera real  porque la carta más alta no es el As.
</p>
<p>
    Póker:  cuatro cartas del mismo valor y una carta no emparejada. En el caso
    de que el  póker se componga de las cartas comunes, el kicker o la carta más
    alta se  considerarán para el mejor mano.
</p>
<p>
    Full:  tres cartas del mismo valor y las dos restantes del mismo valor
    (obviamente  diferentes de las del trío).
</p>
<p>
    Color:  cinco cartas del mismo palo no en secuencia. Si dos jugadores tienen
    un color,  el jugador con la carta más alta ganará. Si esto es lo mismo, el
    jugador con la  segunda carta más alta ganará, etc.
</p>
<p>
    Escalera:  cinco cartas en secuencia, pero de diferente palo. El As se puede
    usar para  hacer la escalera antes del 2 o después de la K. Si ambos
    jugadores muestran  una escalera, el que tenga la escalera que termina con
    la carta más alta gana.
</p>
<p>
    Trío:  tres cartas del mismo valor. Si dos jugadores tienen el mismo trío,
    se  compararán las cuartas cartas más altas y, en caso de empate, se
    compararán las  quintas cartas.
</p>
<p>
    Doble  pareja: combinación de dos parejas de cartas del mismo valor. En el
    caso de que  el rival tenga doble pareja, se compararán las parejas más
    altas; por lo tanto,  en el caso de la igualdad, se compararán las segundas
    parejas y si estas  últimas son iguales, se compararán las quintas cartas
    («kicker»).
</p>
<p>
    Pareja:  combinación de dos cartas del mismo valor. Si las parejas son
    iguales, se  compararán los valores de las cartas no emparejadas: la carta
    más alta  («kicker») determinará el ganador de la mano.
</p>
<p>
    Carta  alta: cualquier mano que no se encuentre dentro de lo mencionado.
    Cuando el  jugador no tiene ninguna de las combinaciones descritas
    anteriormente, el  jugador con la carta más alta gana la mano.
</p>
<p>
    Nota:  Si dos jugadores tienen la misma mano, el ganador se establece en
    base al  kicker y no por el valor del palo (por lo tanto, Corazones,
    Diamantes, Trébol y  Picas no afectan a la asignación de la mano ganadora).
</p>
<p>
    <strong>Acciones del juego</strong>
</p>
<p>
    Se  pueden distinguir cinco fases del juego para cada mano. En cada ronda de
    apuestas,  los jugadores que participan en el bote pueden elegir una de
    estas opciones:
</p>
<p>
    Abandonar  (fold) o retirarse del juego, perdiendo todo el dinero o fichas
    apostadas hasta  ese momento;
</p>
<p>
    Pasar  (check) es decir, deje actuar al siguiente jugador sin apostar nada
    en el bote;
</p>
<p>
    Apostar  (bet) o invertir dinero y / o fichas en el bote;
</p>
<p>
    Subir  (raise) o aumentar una apuesta hecha por un jugador anterior; el
    primer  aumento, para ser considerado como tal, debe ser al menos el doble
    de la  apuesta anterior. Las apuestas posteriores deben ser al menos iguales
    al doble  del último aumento menos la cantidad del aumento o la apuesta
    anterior.
</p>
<p>
    Ver  (Igualar)(call), es decir, cubrir la apuesta realizada por otro
    jugador;
</p>
<p>
    obviamente,  solo tres de las cinco opciones son posibles en función de la
    acción realizada  por los jugadores antes que nosotros o en función de la
    posición del juego.
</p>
<p>
    All-in  significa la acción de un jugador que apuesta todas las fichas que
    quedan.  Todos los jugadores activos en la misma mano pueden ver y
    eventualmente  aumentar contra la suma All-in del jugador, quien puede
    continuar jugando sin  ser obligado a ver o cubrir esas cantidades.
    Obviamente, el jugador que hizo  All-in puede ganar el bote solo por la
    cantidad exacta de su All-in  incrementada por cada call recibida durante
    esa mano. Todas las apuestas y  aumentos realizados después de este punto
    constituyen un premio secundario  (bote lateral) que sigue siendo disputado
    por los jugadores restantes.
</p>
<p>
    <strong>Desarrollo del juego</strong>
</p>
<p>
    <strong>Blinds (Ciegas)</strong>
</p>
<p>
    En  Texas Hold’em tienes dos apuestas forzadas llamadas ciegas.
    Específicamente, la  ciega pequeña y la ciega grande, que llevan a cabo los
    dos jugadores a la  izquierda del dealer, específicamente a la izquierda del
    dealer habrá la ciega  pequeña y a la izquierda de la ciega pequeña habrá la
    ciega grande. Las dos  apuestas previas al flop se juegan simultáneamente y
    generalmente son una (la  ciega grande) dos veces la otra (la ciega
    pequeña). Estas apuestas obligatorias  aseguran que haya una apuesta para
    jugar en cada mano. Dependiendo de la  estructura del juego, se le puede
    solicitar a cada jugador que realice la  apuesta antes (otro tipo de apuesta
    forzada, generalmente menos que las  ciegas). Posteriormente, cada jugador
    recibe sus propias dos cartas ocultas.
</p>
<p>
    <strong>Pre-Flop</strong>
</p>
<p>
    Se  repartirán dos cartas en el sentido de las agujas del reloj, una a la
    vez, a  cada jugador, comenzando con el jugador sentado a la izquierda del
    botón del  dealer. El primer jugador a la izquierda de la ciega grande
    comienza la primera  ronda de apuestas. La ronda de apuestas continúa en el
    sentido de las agujas  del reloj alrededor de la mesa, con cada jugador
    tomando turnos. En esta ronda  de apuestas, cada jugador debe «igualar» al
    menos a la ciega grande para  permanecer en el juego. La apuesta mínima en
    esta ronda es la apuesta mínima de  la mesa.
</p>
<p>
    <strong>Flop</strong>
</p>
<p>
    Después  de la primera ronda de apuestas, el dealer coloca tres cartas en la
    mesa con la  cara hacia arriba. Las primeras tres cartas comunes disponibles
    para todos los  jugadores activos representan el flop. El primer jugador
    activo a la izquierda  del dealer comienza la segunda ronda de apuestas, la
    cual continúa en sentido  horario alrededor de la mesa, con cada jugador
    activo por turno.
</p>
<p>
    <strong>Turn</strong>
</p>
<p>
    Al  final de la segunda ronda de apuestas, el dealer coloca otra carta
    abierta (Turn)  en la mesa y comienza la tercera ronda de apuestas.
</p>
<p>
    <strong>River</strong>
</p>
<p>
    El  Dealer coloca otra carta boca arriba (River) en la mesa, la quinta y la
    final,  y comienza la última ronda de apuestas.
</p>
<p>
    <strong>Showdown</strong>
</p>
<p>
    Una  vez que todas las rondas de apuestas han finalizado, el competidor que
    logra  tener la mejor manocon cinco cartas, usando las dos cartas privadas y
    las cinco  en común, gana la mano. En caso de empate, el bote se dividirá en
    partes  iguales entre los competidores restantes hasta el enfrentamiento. En
    Texas  Hold’em, los palos de las cartas tienen el mismo valor. Quienquiera
    que  participe, es decir, pone todas sus fichas en el bote para aumentar o
    cubrir al  menos parcialmente una apuesta opuesta en cualquier fase del
    juego, participa  en el desarrollo de la mano hasta el showdown,
    contribuyendo a la ganancia en  relación con la parte del bote cubierto con
    las fichas de la apuesta.
</p>
<p>
    <strong>Six Plus (6+) Holdem Poker</strong>
</p>
<p>
    6+  Holdem es un formato de póquer de «baraja reducida» Se juega como el
    Texas  Holdem, pero con algunas pequeñas diferencias:
</p>
<p>
    Todas  las cartas inferiores a seis se eliminan de la baraja.
</p>
<p>
    Todos  apuestan un ante y solo el jugador en el botón apuesta la ciega
    grande
</p>
<p>
    Color  le gana a full.
</p>
<p>
    <strong>Blinds (Ciegas)</strong>
</p>
<p>
    6+  Holdem utiliza una estructura de «botón ciego»: todos los jugadores
    ponen ante,  y el jugador sentado en la posición del botón es el único que
    coloca una ciega  grande.
</p>
<p>
    La  acción comienza con el jugador sentado a la izquierda del botón. Cada
    mano se  juega de acuerdo a las reglas normales del Texas Holdem, con las
    mismas rondas  de apuestas: pre-flop, flop, turn y river.
</p>
<p>
    Si  has jugado al Texas Holdem anteriormente, el 6+ Holdem resultará fácil
    de  comprender en sus dinámicas.
</p>
<p>
    <strong>Hands Ranking (clasificación de manos)</strong>
</p>
<p>
    La  siguiente tabla ilustra cómo cambia la clasificación de manos en 6+
    Hold’em  para adaptarse al mazo más corto:
</p>
<p>
    <strong>Omaha</strong>
</p>
<p>
    Omaha  es una variante del juego con cartas comunitarias, similar al Texas
    Hold’em  pero con algunas diferencias significativas en términos de reglas y
    estrategia.
</p>
<p>
    En  Omaha, cada jugador recibe inicialmente cuatro cartas boca abajo, a
    diferencia  de Texas Hold’em, donde se reparten dos. Posteriormente, durante
    el transcurso  de la mano, el Dealer distribuye las cinco cartas
    comunitarias sobre la mesa.  El número mínimo de jugadores esperado es 2,
    mientras que el número máximo es  de 9 jugadores. Para formar la mano de
    póker de cinco cartas, se deben usar dos  de las cuatro cartas recibidas y
    tres de las cinco cartas comunitarias. Omaha  puede tener diferentes
    estructuras de límite en apuestas como «Fixedlimit» y  «Potlimit».
</p>
<p>
    <strong>Fixed limit (FL) o limit(L)</strong>
</p>
<p>
    En  las dos primeras rondas de apuestas (preflop y flop), la apuesta y el
    aumento  están vinculados al tamaño de la ciega grande con un máximo de 3
    aumentos en la  apuesta original; en el turn y el river se aplica la misma
    lógica, pero la  cantidad que se puede apostar corresponde al doble de la
    ciega grande.
</p>
<p>
    <strong>Potlimit(PL)</strong>
</p>
<p>
    En  PotLimit Omaha, la apuesta mínima es la ciega grande y los participantes
    tienen  la opción de apostar hasta del máximo del bote. El aumento mínimo no
    puede ser  inferior a la apuesta anterior o aumento de la misma mano. La
    apuesta máxima  viene dada por la cantidad del bote, incluida la apuesta de
    aquellos que desean  subir. Por ejemplo, en elpreflop el primer jugador en
    actuar, puede apostar 3 ½  de la ciega grande. En caso de que quieras subir
    y haya unade las ciegas, el  aumento máximo permitido será de 3 ciegas
    grandes.
</p>
<p>
    <strong>Las posibles combinaciones de manos</strong>
</p>
<p>
    Escalera  real: la mejor mano de póker, en secuencia Diez, Jack, Reina, Rey
    y As del  mismo palo.
</p>
<p>
    Escalera  de color: cinco cartas del mismo palo en secuencia. Difiere de la
    escalera real  porque la carta más alta no es el As.
</p>
<p>
    Póker:  cuatro cartas del mismo valor y una carta no emparejada. En el caso
    de que el  póker se componga de las cartas comunes, el kicker o la carta más
    alta se  considerarán para la mejor mano.
</p>
<p>
    Full:  tres cartas del mismo valor y las dos restantes del mismo valor
    (obviamente  diferentes de las del trío).
</p>
<p>
    Color:  cinco cartas del mismo palo no en secuencia. Si dos jugadores tienen
    un color,  el jugador con la carta más alta ganará. Si esto es lo mismo, el
    jugador con la  segunda carta más alta ganará, etc.
</p>
<p>
    Escalera:  cinco cartas en secuencia, pero de diferente palo. El As se puede
    usar para  hacer la escalera antes del 2 o después de la K. Si ambos
    jugadores muestran  una escalera, el que tenga la escalera que termina con
    la carta más alta gana.
</p>
<p>
    Trío:  tres cartas del mismo valor. Si dos jugadores tienen el mismo trío,
    se  compararán las cuartas cartas más altas y, en caso de empate, se
    compararán las  quintas cartas.
</p>
<p>
    Doble  pareja: combinación de dos parejas de cartas del mismo valor. En el
    caso de que  el rival tenga doble pareja, se compararán las parejas más
    altas; por lo tanto,  en el caso de la igualdad, se compararán las segundas
    parejas y si estas  últimas son iguales, se compararán las quintas cartas
    («kicker»).
</p>
<p>
    Pareja:  combinación de dos cartas del mismo valor. Si las parejas son
    iguales, se  compararán los valores de las cartas no emparejadas: la carta
    más alta («kicker  «) determinará el ganador de la mano.
</p>
<p>
    Carta  alta: cualquier mano que no se encuentre dentro de lo mencionado.
    Cuando el  jugador no tiene ninguna de las combinaciones descritas
    anteriormente, el  jugador con la carta más alta gana la mano.
</p>
<p>
    Nota:  Si dos jugadores tienen la misma mano, el ganador se establece en
    base al  kicker y no por el valor del palo (por lo tanto, Corazones,
    Diamantes, Trébol y  Picas no afectan a la asignación de la mano ganadora).
</p>
<p>
    <strong>Acciones del juego</strong>
</p>
<p>
    Se  pueden distinguir cinco fases del juego para cada mano. En cada ronda de
    apuestas, los jugadores que participan en el bote pueden elegir una de estas
    opciones:
</p>
<p>
    Abandonar  (fold) o retirarse del juego, perdiendo todo el dinero o fichas
    apostadas hasta  ese momento;
</p>
<p>
    Pasar  (check) es decir, deje actuar al siguiente jugador sin apostar nada
    en el bote;
</p>
<p>
    Apostar  (bet) o invertir dinero y / o fichas en el bote;
</p>
<p>
    Subir  (raise) o aumentar una apuesta hecha por un jugador anterior; el
    primer  aumento, para ser considerado como tal, debe ser al menos el doble
    de la  apuesta anterior. Las apuestas posteriores deben ser al menos iguales
    al doble  del último aumento menos la cantidad del aumento o la apuesta
    anterior.
</p>
<p>
    Ver(Igualar)  (call), es decir, cubrir la apuesta realizada por otro
    jugador;
</p>
<p>
    All-in  significa la acción de un jugador que apuesta todas las fichas que
    quedan.  Todos los jugadores activos en la misma mano pueden ver y
    eventualmente aumentar  contra la suma All-in del jugador, quien puede
    continuar jugando sin ser  obligado a ver o cubrir esas cantidades.
    Obviamente, el jugador que hizo All-in  puede ganar el bote solo por la
    cantidad exacta de su All-in incrementada por  cada call recibida durante
    esa mano. Todas las apuestas y aumentos realizados  después de este punto
    constituyen un premio secundario (bote lateral) que sigue  siendo disputado
    por los jugadores restantes.
</p>
<p>
    Solo  tres de las seis opciones son posibles según la acción realizada por
    los  jugadores antes que nosotros o según la posición del juego.
</p>
<p>
    <strong>Desarrollo del juego</strong>
</p>
<p>
    El  botón indica la posición del dealer y se mueve en el sentido de las
    agujas del  reloj hacia cada mano, de manera idéntica al Texas Hold’em.
</p>
<p>
    El  jugador a la izquierda del dealer debe colocar la ciega pequeña y el
    jugador a  su izquierda debe colocar la ciega grande para comenzar la mano
    del juego. Por  ejemplo, en un juego de 1/2 €, Ciega pequeña es igual a € 1
    y ciega grande es  igual a € 2.
</p>
<p>
    A  cada jugador se le reparten cuatro cartas boca abajo, una a la vez, por
    lo que  realiza una ronda de apuestas como Texas Hold’Em.
</p>
<p>
    El  juego ahora sigue la misma tendencia que Texas Hold’Em, en el que el
    dealer  distribuye el Flop, el Turn y el River, o las cartas comunes de la
    mesa, con  las mismas rondas de apuestas.
</p>
<p>
    Después  de que se hayan completado todas las apuestas, se llega al
    enfrentamiento y la  determinación del ganador del bote. El que hacela mejor
    mano gana. Cada uno  muestra sus propias cartas. La combinación que es
    válida para determinar la  mejor mano se obtiene combinando 2 de las cartas
    de las que el jugador dispone  con las 3 de las cartas comunitarias. Si dos
    o más jugadores tienen la misma  mano, el bote se dividirá en partes iguales
    entre ellos.
</p>
<p>
    <strong>Torneos programados y Sit&amp;Go</strong>
</p>
<p>
    El  jugador, en el modo de juego TORNEO, da su consentimiento para el pago,
    con el  propósito de participar, de una tarifa de registro (llamada
    «Buy-in»). El  jugador acepta y se hace consciente del hecho de que, contra
    el pago de la  tarifa antes mencionada, a los participantes, en el momento
    del inicio del  torneo, se les asigna una cantidad fija de fichas que es la
    misma para todos  los participantes.
</p>
<p>
    Si  el torneo está programado, el tiempo de inicio está predeterminado y se
    puede  consultar en el Lobby.
</p>
<p>
    Si  el torneo es Sit&amp;Go, el número de participantes registrados está
    predefinido y la hora de inicio está sujeta al registro del último jugador.
</p>
<p>
    Cualquier  información relacionada con cada torneo que incluya, a modo de
    ejemplo, entre  otros, el número de jugadores registrados y / o el bote de
    premios se puede  consultar en el Lobby.
</p>
<p>
    El  jugador puede unirse a un torneo programado en el intervalo de tiempo
    entre la  apertura del registro y el cierre de las entradas. El jugador
    puede cancelar el  registro en cualquier momento antes de que comience el
    torneo.
</p>
<p>
    El  registro para los torneos Sit&amp;Go solo se permite si hay asientos
    disponibles. El jugador puede cancelar la inscripción a un torneo Sit&amp;Go
    en  cualquier momento, excepto en el caso de que todos los asientos
    ofrecidos estén  ocupados.
</p>
<p>
    En  caso de cancelación de registro para un torneo, el jugador debe recibir
    el  Buy-in. Cuando el jugador ha ganado el lugar de participación a través
    de un  torneo satélite, recibirá el reembolso de la cuota en forma de token
    (moneda  que solo puede ser usada en los torneos).
</p>
<p>
    Nota:  Además de las estructuras regulares, los torneos también pueden tener
    una  estructura turbo, lo que significa que son más rápidos.
</p>
<p>
    «Turbo»,  «Super turbo» o «Hyper turbo» son las opciones disponibles que van
    desde lo más  lento hasta lo más rápido.
</p>
<p>
    <strong>Desarrollo del juego</strong>
</p>
<p>
    La  distribución de asientos en la mesa de juego durante un torneo será
    realizada  por el software de manera aleatoria y automática, antes de que
    comience el  juego. Los jugadores no pueden elegir ni cambiar sus asientos.
    De la misma  manera, el que tome el papel de Dealer en primera mano será
    seleccionado. Al  comienzo de la primera mano, el botón Dealer se colocará
    frente al asiento  número 1.
</p>
<p>
    La  distribución de las cartas en sentido horario es automática.
</p>
<p>
    La  participación de los jugadores trasladados a otra mesa tendrá lugar en
    tiempo  real.
</p>
<p>
    Las  ciegas son apuestas obligatorias. En los torneos, esta cantidad
    predeterminada  aumenta de acuerdo con un modelo de tiempo para garantizar
    la eliminación  progresiva de los jugadores. Los jugadores que no estén
    conectados / activos en  la mesa apostarán automáticamente las ciegas hasta
    que sean eliminados.
</p>
<p>
    En  cada mano, cada jugador tiene que apostar la ciega pequeña (Small Blind)
    y La  ciega grande (Big Blind).
</p>
<p>
    Al  equilibrar las mesas, puede suceder que el mismo jugador apueste la
    ciega  grande más de una vez durante una mano.Tales casos no afectan la
    equidad del  juego ya que ocurren raramente y de manera aleatoria.
</p>
<p>
    En  los torneos, en cada mano, el jugador tiene un tiempo máximo establecido
    para  hacer una apuesta. Dependiendo de la estructura de la mesa, los
    jugadores  tienen 25 o 15 segundos disponibles para actuar en cada turno.
    Los jugadores  también pueden tener un banco de tiempo de 30, 60 o 90
    segundos que pueden  activar y darse tiempo para actuar. Una vez que se
    agote el banco de tiempo  disponible, el jugador irá a sentarse. El jugador
    sentado todavía recibirá sus  2 cartasde mano, pero se retirará
    automáticamente.
</p>
<p>
    El  torneo finaliza cuando el ganador obtiene el premio del bote, la
    cantidad de  las apuestas en efectivo o en fichas de todos los
    participantes, con la  consiguiente eliminación de los competidores de la
    carrera hacia el premio  final.
</p>
<p>
    Por  lo tanto, todos los torneos regulares duran hasta que todos los
    jugadores,  excepto el ganador, hayan sido eliminados. En el caso de los
    torneos satélite  cuando los jugadores están en la misma posición de
    clasificación con más  premios / lugares de igual valor, el torneo se
    detendrá y la clasificación se  basará en la posición actual. En caso de
    empate, el jugador en la primera  posición (más cercano al dealer) se
    colocará en el lugar más alto de la lista.
</p>
<p>
    El  ganador puede recibir un Premio y / o un boleto para participar en
    iniciativas  promocionales dedicadas. En caso de no disponibilidad de
    jugadores invitados a  participar en eventos sujetos a iniciativas
    promocionales, elProveedor asignará  la invitación al jugador colocado en la
    siguiente posición dentro del torneo de  referencia. El boleto para
    participar en eventos sujetos a iniciativas  promocionales es estrictamente
    personal y no puede transferirse a terceros por  ningún motivo o causa. La
    oferta de un Premio específico no puede convertirse  en efectivo ni
    transferirse a terceros.
</p>
<p>
    En  los torneos, la clasificación final se determinará en función de los
    tiempos de  eliminación; El primer jugador en ser eliminado será posicionado
    como el último  en el ranking.
</p>
<p>
    En  los torneos de mesas múltiples se respetará un descanso de cinco minutos
    cada  55 minutos después de la hora exacta. Si el torneo comienza 40 minutos
    después  de la hora exacta, no se respetará el primer descanso.
</p>
<p>
    <strong>Política del Licenciatario /concesionario</strong>
</p>
<p>
    El  Licenciatario se reserva el derecho de cancelar o reprogramar torneos en
    cualquier momento, por cualquier motivo, razón y / o causa.
</p>
<p>
    El  Licenciatario se reserva el derecho de publicar en el sitio web y / o
    utilizar  el apodo del ganador del torneo para cualquier iniciativa de
    marketing.
</p>
<p>
    El  licenciatario se reserva el derecho de cambiar las reglas y criterios de
    los  torneos de póker en cualquier momento.
</p>
<p>
    El  progreso de los torneos presentes en el Lobby de juegos será monitoreado
    por el  Equipo anticolusión que se reserva el derecho de tomar las medidas
    necesarias  como se especifica en el párrafo
</p>
<p>
    En  el caso de que las sesiones de juego se cancelen por razones técnicas,
    el  premio acumulado se suspende. Los jugadores recibirán un reembolso del
    valor de  las tarifas de participación pagadas, de una manera que se
    detallará más  adelante en la sección correspondiente del reglamento.
</p>
<p>
    <strong>Tarifa (tarifa para el licenciatario)</strong>
</p>
<p>
    La  tarifa es el porcentaje de participación de la sala de póker en la
    tarifa de  registro para un torneo o Sit&amp;Go. La tarifa deducida del
    coste de  inscripción siempre se indica en la descripción del Evento (Torneo
    o  Sit&amp;Go) en el que desea participar. La tarifa no puede ser superior
    al 17%  bajo ninguna circunstancia.
</p>
<p>
    <strong>Tipos de torneo</strong>
</p>
<p>
    Freezout:  con una sola posibilidad de entrada. Una vez eliminado, ya no
    tienes  posibilidades de seguir jugando ese torneo en particular.
</p>
<p>
    6-Max:  juega con hasta 6 personas en cada mesa.
</p>
<p>
    Satélite:  este es un torneo clasificatorio cuyo premio es el acceso a un
    torneo mayor  posterior, tanto en línea como en vivo
</p>
<p>
    Rebuy-Add-on:  Torneos específicos donde puedes, al principio, añadir más
    fichas con una  recompra o compra de la cantidad inicialdurante el juego
    después de perder  todas las fichas. También puedesrealizar un Rebuy-Add-On
    en un momento  especifico que te daría una cantidad adicional de fichas
    establecidas en el  torneo.
</p>
<p>
    Recompensa:  es un torneo que además del acumulado de premios
    normalesgarantiza un premio  que se otorga al jugador que elimina a
    cualquier otro jugador
</p>
<p>
    Freerolls:  la entrada al torneo es gratuita y el premio lo ofrece El
    Licenciatiario
</p>
<p>
    <strong>Política de suspensión y cancelación del torneo.</strong>
</p>
<p>
    Si  se suspende un torneo debido a una interrupción, la Red intentará
    reiniciar el  torneo tan pronto como la mayoría de los jugadores se hayan
    reconectado. Si el  período de inactividad se prolonga demasiado, el torneo
    se cancelará y se  aplicarán las condiciones de cancelación.
</p>
<p>
    En  el caso improbable de una interrupción prolongada, los torneos se
    cancelarán y  los premios se distribuirán de la siguiente manera: en el caso
    de que los  jugadores aún no tengan una prima, el premio acumulado se
    distribuirá 50% por  igual entre todos los jugadores restantes en juego, y
    el 50% restante se  distribuirá proporcionalmente a los jugadores restantes
    en función de sus  montones de fichas en el momento de la cancelación.
</p>
<p>
    Si  los jugadores ya estánen la fase de los premios, todos los jugadores
    restantes  en el juego recibirán el premio para el siguiente paso en el
    pago. El bote de  premios residual se distribuirá en proporción a los
    jugadores restantes en el  juego en función de sus cantidades de fichas en
    el momento de la cancelación.
</p>
<p>
    En  el caso de torneos satélites, pueden ocurrir excepciones a lo anterior.
    Si un  torneo satélite está cerca de su conclusión, la Red puede decidir
    asignar a los  jugadores el derecho a participar en iniciativas
    promocionales, en lugar de  pagar las ganancias en efectivo o reembolsar el
    torneo.
</p>
<p>
    <strong>Juego Efectivo</strong>
</p>
<p>
    En  el modo de juego en efectivo, el jugador puede unirse o abandonar una
    mesa en  cualquier momento, incluso participando en una sola mano. El valor
    de cada  ficha jugada corresponde a su valor nominal en efectivo. No hay
    pausas en el  juego. El jugador debe registrarse llevando una cantidad entre
    el mínimo y el  máximo establecido por la mesa y puede agregar otras fichas
    a su montón en  cualquier momento. Estas fichas se acreditarán a la mesa de
    juego al final de  la mano actual y estarán disponibles para el jugador para
    las manos  posteriores.
</p>
<p>
    A  diferencia del torneo, el Blind o el Ante no varían según el tiempo
    transcurrido, pero cada mesa tiene su propio Blind/Ante predeterminado y se
    muestran tanto en el Lobby como en las mesas.
</p>
<p>
    Durante  el juego, cada jugador puede realizar la Recompra o la recompra de
    fichas, que  puede ser igual o diferente a la inicial.
</p>
<p>
    Cuando  el jugador elige sentarse en uno de los asientos libres en la mesa
    él es  Sitout, ysolo comenzará a jugar a la llegada de la ciega grande o
    pagará por  adelantado, en comparación con la posición en la que se
    encuentra la ciega  grande.
</p>
<p>
    Si  el jugador abandona una mesa ganadora, puede regresar a la misma mesa
    con una  suma igual o mayor, hasta el límite permitido, a la victoria
    anterior.
</p>
<p>
    Al  final de cada mano o sesión de juego, el jugador que ha obtenido la
    combinación  ganadora gana el bote o parte de él.
</p>
<p>
    Comportamiento  incorrecto en mesas de efectivo
</p>
<p>
    Cuando  un jugador se sienta en las mesas con la intención de poner en
    práctica  prácticas prohibidas que tienden a limitar el derecho de otros
    jugadores a  jugar.
</p>
<p>
    Particularmente  en las mesas de dos jugadores:
</p>
<p>
    Solo  se permite un espacio de espera por nivel.
</p>
<p>
    No  está permitido mantener una o más mesas de dos jugadores bloqueadas al
    permanecer sentados, incluso si la mesa se ha abierto en persona (cada
    jugador  tiene la posibilidad de decidir no jugar contra algunos oponentes,
    en este caso  es suficiente con no sentarse con ellos o levantarse).
</p>
<p>
    No  está permitido sentarse para evitar que otros jugadores jueguen.
</p>
<p>
    No  está permitido perder tiempo cada vez que te toca a ti jugar para
    frenarla  acción.
</p>
<p>
    No  está permitido, después de la eliminación automática de los SitOuts,
    volver a  la mesa y volver a sentarse.
</p>
<p>
    La  práctica de «abotonarse» no está permitida, es decir, asegurarse de
    jugar  siempre desde el botón.
</p>
<p>
    En  mesas con más de dos asientos:
</p>
<p>
    No  está permitido sentarse para evitar que otros jugadores jueguen o
    esperar a que  uno o más de sus jugadores favoritos vengan a la mesa.
</p>
<p>
    No  está permitido perder tiempo sistemáticamente cada vez que sea tu turno
</p>
<p>
    La  práctica de entrar y salir de la misma mesa repetidamente no está
    permitida
</p>
<p>
    La  función de «SitOut» debe ser utilizada por jugadores con sentido común,
    con el  único propósito de omitir algunas manos por razones justificadas,
    sin el riesgo  de perder la ronda del juego.
</p>
<p>
    Política  de cancelación de un juego de efectivo a causa de un mal
    funcionamiento del  servidor
</p>
<p>
    En  caso de un bloqueo del servidor, se cancelarán todas las manos en
    progreso. El  número de fichas de cada jugador se devolverá a la cantidad
    inicial en la mano.  Para una revisión más detallada de las quejas
    individuales, los jugadores deben  contactar al servicio de atención
    alcliente apropiado. Durante el período de  inactividad planificado
    (mantenimiento), los servidores se suspenderán unos  minutos antes de
    permitir la finalización de las manos en progreso y evitar el  inicio de
    otras nuevas. Los servidores se reiniciarán automáticamente al final  del
    período de inactividad.
</p>
<p>
    <strong>Rake</strong>
</p>
<p>
    El  rastrillo, la tarifa no devuelta en premios y retirada por el
    Licenciatario  como comisión por sus servicios, es obtenida del bote, en la
    medida de lo  posible. El rastrillo no se retira del bote en el caso de que
    la mano termine  antes de que las cartas de Flop se distribuyan en Texas
    Hold’em y Omaha. Solo  los jugadores a quienes se les reparten las cartas
    (jugadores activos) serán  considerados con el propósito de aplicar el
    Rastrillo. El rastrillo varía según  el tipo de juego, el número de
    jugadores por mesa y los límites del juego de la  mesa.
</p>
<p>
    <strong>Reglas de comportamiento</strong>
</p>
<p>
    <strong>Inactividad</strong>
</p>
<p>
    Un  jugador inactivo durante 20 minutos en la sala de póker se desconectará
    automáticamente de ella.
</p>
<p>
    <strong>Usando chat</strong>
</p>
<p>
    Se  informa a los jugadores que todos los mensajes del chat se graban en la
    plataforma de juego.
</p>
<p>
    Los  jugadores se comprometen a cumplir con el código de ética del
    Licenciatario. El  uso de insultos, lenguaje ofensivo y / o vulgar,
    expresiones xenófobas y  racistas, intimidación y blasfemia contra cualquier
    competidor, visitante y / o  empleado está estrictamente prohibido. El único
    idioma utilizado y / o  utilizable en el chat es el español.
</p>
<p>
    No  está permitido usar el chat para difundir datos sensibles o información
    que  rastree o puede conducir a la identidad de personas reales.
</p>
<p>
    Los  jugadores que contravengan estas reglas de uso recibirán una
    prohibición de  chat de una duración acorde con el alcance de la infracción,
    de acuerdo con el  juicio incuestionable del Concesionario.
</p>
<p>
    Para  aquellos que ofrecen la devolución del rastrillo en el chat, se les
    aplicará la  expulsión definitiva del póker.
</p>
<p>
    Los  jugadores que noten comportamientos contrarios a la regulación, pueden
    informarlo al servicio de atención al cliente correspondiente. Los usuarios
    deben estar registrados regularmente para poder realizar informes.
</p>
<p>
    No  está permitido usar el chat o cualquier otro medio (MSN, Skype, móvil,
    etc.)  para colaborar con algunos jugadores con fines colusorios, para
    realizar  irregularidades de cualquier tipo (a modo de ejemplo y no limitado
    a la  participación en eventos) Sit&amp;Go con el propósito de transferir
    dinero a  una cuenta de juego específica) o fraude contra otros jugadores y
    el  concesionario.
</p>
<p>
    <strong>Apodo</strong>
</p>
<p>
    El  apodo elegido durante el proceso de registro tiene un valor temporal y
    es  confirmado por el Licenciatario cuando se identifica al jugador.
</p>
<p>
    El  apodo no puede contener insultos, lenguaje ofensivo y / o vulgar,
    expresiones  xenófobas y racistas, intimidación y blasfemia, ser engañoso o
    promocional de  marcas competidoras.
</p>
<p>
    El  Licenciatario se reserva el derecho de cambiar los apodos que no
    respetan estos  criterios.
</p>
<p>
    <strong>Número de direcciones IP idénticas permitidas</strong>
</p>
<p>
    Para  evitar la posibilidad de fraude, el acceso a los eventos está limitado
    de la  siguiente manera:
</p>
<p>
    1  usuario individual conectado por la misma dirección IP en cada mesa de
    efectivo
</p>
<p>
    1  usuario individual conectado por la misma dirección IP en cada Sit&amp;Go
</p>
<p>
    Número  máximo de 1 usuario conectado por la misma dirección IP en cada
    torneo.
</p>
<p>
    <strong>
        FRAUDE DE IRREGULARIDADES Y MEDIDAS RELATIVAS POR EL  LICENCIATARIO
    </strong>
</p>
<p>
    No  está permitido hacer traspasos de fichas entre los Usuarios de forma
    voluntaria  (descarga de fichas) por ningún motivo y / o causa, usar
    múltiples cuentas,  programas prohibidos, inteligencia artificial (BOT), y
    participar en juegos de  equipo dirigidos a la pérdida voluntaria de fichas
    para favorecer aotros  participantes (colusión). En los casos antes
    mencionados, el Licenciatario  dispondrá del cierre repentino de la Cuenta,
    la terminación del contrato y la  notificación simultánea a la Autoridad
    Judicial para la compensación de los  daños sufridos de conformidad con el
    art. 1218 c.c.
</p>
<p>
    El  Usuario consiente en el pago de una multa igual a las cantidades pagadas
    indebidamente por comportamiento fraudulento y / o colusorio.
</p>
<p>
    El  Usuario autoriza a Licenciatario a compensar cualquier saldo positivo
    presente  en su Cuenta de Juego con la cantidad de la multa antes
    mencionada.
</p>
<p>
    Cuando  el saldo de la cuenta de juego es negativo, el licenciatario se
    reserva el  derecho de emprender acciones legales para la recuperación del
    crédito, sujeto  a una compensación por el mayor daño sufrido.
</p>
<p>
    Está  estrictamente prohibido que el Usuario revele las cartas en su poder o
    las que  ya se descartaron, lo que ralentiza innecesariamente el curso del
    juego e  intimida a un competidor al hacer que abandone la mesa.
</p>
<p>
    Las  manos no pueden comentarse hasta que se complete la acción.
</p>
<p>
    El  usuario acepta no favorecer ninguna forma de juego suave, jugando de
    forma no  agresiva contra un competidor con la intención de avanzar. En tal
    caso, el  Licenciatario puede descalificar al Usuario, suspender la Cuenta y
    / o  acreditar cualquier Bono.
</p>
<p>
    <strong>Bonificaciones, FPP e iniciativas promocionales</strong>
</p>
<p>
    El  Licenciatario realizará controles en toda la secuencia de jugadas. Si
    como  resultado de estos controles, antes o después del pago de los bonos,
    el usuario  es declarado culpable de «abuso de la promoción», el
    Licenciatario, a su  exclusivo criterio, se reserva el derecho de:
</p>
<p>
    cancelar  o revisar promociones activas y finalizar el pago de bonos y su
    liberación
</p>
<p>
    revocar  los bonos recibidos que aún no se han utilizado
</p>
<p>
    cancelar  o suspender cualquier ganancia en efectivo derivada del uso de
    bonos
</p>
<p>
    cancelar  retiros
</p>
<p>
    excluir  al usuario de promociones posteriores
</p>
<p>
    suspender  o cancelar inmediatamente la cuenta de juego
</p>
<p>
    Bonificaciones  no utilizadas, reclamadas o canjeadas (incluido pero no
    limitado a FPPpoints  (puntos frecuentes del jugador) y al programa de bonos
    VIP)al finalizar  cualquier promoción, pueden ser canceladas por el
    Licenciatario a su único  criterio.
</p>
<p>
    El  Licenciatario se reserva el derecho de eliminar los puntos FPP de la
    cuenta de  los jugadores que no lo utilizan y que no se convierten en
    Bonificaciones,  boletos o fichas dentro de los 90 días. Este requisito
    también se aplica en los  casos en que el jugador, durante los 90 días
    mencionados anteriormente,  participó activamente en las sesiones de juego.
</p>
<p>
    En  el caso de que un bono se pague incorrectamente a un jugador, el dealer
    se  reserva el derecho de solicitar la devolución.
</p>
<p>
    El  Concesionario se reserva el derecho de interrumpir las iniciativas de
    promoción  en cualquier momento, de conformidad con el Reglamento actual.
</p>
<p>
    Cualquier  bonificación ganada se acreditará dentro de las 72 horas hábiles
    posteriores al  cierre de la promoción o torneo.
</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.p {
  background-color: #fff;
}
.tabl {
  border: 1px solid #c0c0c0;
  border-collapse: collapse;
  padding: 5px;
  background: white;
  color: black;
  border-radius: 4px !important;
  width: 100%;
}
.tabd {
  border: 1px solid #c0c0c0;
  border-collapse: collapse;
  padding: 10px;
  background: white;
  color: black;
  border-radius: 4px !important;
  width: 100%;
}
.tabl th {
  border: 1px solid #c0c0c0;
  padding: 5px;
  border-radius: 4px !important;
  font-size: 12px !important;
}
.tabl td {
  border: 1px solid #c0c0c0;
  padding: 5px;
  border-radius: 4px !important;
}
</style>
